<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="title mb-3">{{ objSelectedDoctor.doctorName }}</div>
        <div>{{ objSelectedDoctor.doctorSpecialization }}</div>
        <div v-if="objSelectedDoctor.serviceName">
          <strong>Услуга: </strong>{{ objSelectedDoctor.serviceName }}
        </div>
        <div><strong>Клиника: </strong>{{ objDepartment.name }}</div>
        <div><strong>Адрес: </strong>{{ objDepartment.address }}</div>
        <div
          v-if="
            objSelectedDoctor.servicePrice ||
            objSelectedDoctor.doctorServicePrice
          "
        >
          <strong>Цена: </strong
          >{{
            objSelectedDoctor.servicePrice ||
            objSelectedDoctor.doctorServicePrice
          }}
        </div>
      </v-col>
      <v-col>
        <order-form :services="this.orderData" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiArrowLeft } from "@mdi/js";
import { mapGetters } from "vuex";
import * as names from "store/names";
import OrderForm from "components/partials/OrderForm";

export default {
  name: "DepartmentCardDoctorsFast",
  components: { OrderForm },
  data: () => ({
    orderData: [],
    icons: {
      mdiArrowLeft,
    },
  }),
  computed: {
    ...mapGetters({
      objDepartment: names.OBJ_DEPARTMENT_INFO,
      objSelectedDoctor: names.DEPARTMENT_SELECTED_DOCTOR_INFO,
    }),
  },
  mounted() {
    this.collectOrderData();
  },
  methods: {
    returnDetail() {
      this.$drawer.showDrawer(true, "Detail");
    },

    collectOrderData() {
      this.orderData = {
        departmentName: this.objDepartment.name,

        departmentId: this.objDepartment.departmentId,

        departmentAddress: this.objDepartment.address,

        departmentPhone: this.objDepartment.phones[0].phone,

        serviceName: this.objSelectedDoctor.serviceName,

        doctorName: this.objSelectedDoctor.doctorName,

        doctorServicePrice: this.objSelectedDoctor.servicePrice,

        servicePrice: this.objSelectedDoctor.servicePrice,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";

.return-btn {
  color: $deep-blue;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
